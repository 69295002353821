.tree-view-list {
  width: 100%;

  .MuiTreeItem-root {
    border-bottom: 1px solid #f2f2f2;

    .MuiTreeItem-content {
      flex-direction: row-reverse;

      .MuiListItem-root {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid #f2f2f2;
      }
      .MuiListItemText-primary,
      .MuiListItemText-secondary {
        display: flex;
        align-items: center;
        color: unset;
        flex-wrap: wrap;
      }
    }
  }

  .MuiTreeItem-root:last-child {
    border-bottom: unset;
  }

  .content-list {
    display: flex;
    width: 100%;
    align-items: center;
  }
}

.tree-view-list.read-only {
  .MuiTreeItem-root {
    .MuiTreeItem-content {
      padding: 10px 12px;
    }
  }
}
