$share-padding: 8px 12px;

.custom-share-dialog {
  .MuiDialogContent-root {
    padding: 0 !important;

    .custom-share-tabs {
      .MuiTab-root {
        flex: 1 1;
      }
    }

    .share-dialog-add-box {
      padding: $share-padding;
    }
  }
}

.custom-share-list {
  .custom-share-list-title {
    padding: $share-padding 10px;
  }

  .custom-share-list-box {
    max-height: 300px;
    overflow: auto;

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: unset;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: unset;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #e1e1e1;
    }
  }
  .custom-share-list-item {
    padding: 0;

    .custom-share-list-item-text {
      padding: 5px 12px;
      .custom-share-list-select {
        margin: 0 !important;
        padding: 0 5px;
        border-radius: 5px;
      }
      .custom-share-list-select:hover {
        background: #e0e0e0;
      }
    }

    .custom-share-list-item-text:hover {
      background: #e8e8e8;
    }
  }
}

.custom-share-border-box {
  padding: 0 12px;
  .custom-share-border {
    border: 1px solid #e6e6e6;
  }
}
