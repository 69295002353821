.status-block-f{
    font: 600 11px/16px Poppins,sans-serif;
    color: #ff4568;
}

.text-desc{
    color: #454545;
}

.card-form{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.card-form .form-field{
    flex: 1 1 100%
}