$primary-color: #ff9800;
$secondary-color: #faf2e8;
$color-light-blue: #e5edff;
$color-dark-blue: #0019d8;
$color-blue: #2f53e6;
$color-green: #00bf0f;
$color-red: #ff4568;
$color-orange: #ffb11f;
$color-grey: #0000008a;
$color-black: #212121;

.color-grey {
  color: $color-grey;
}
.color-orange {
  color: $color-orange;
}

.color-red {
  color: $color-red;
}

.color-green {
  color: $color-green;
}

.color-blue {
  color: $color-blue;
}
.color-black {
  color: #212121;
}
.color-primary-orange {
  color: $primary-color;
}
.color-secondary-orange {
  color: $secondary-color;
}

button.MuiButton-root.color-nav-alert {
  color: #78a9ff;
}
